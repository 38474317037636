import {GET_CATEGORIES} from '../../shared/constants/ActionTypes';
import {FETCH_SUCCESS} from '../../shared/constants/ActionTypes';
import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export const fetchCategories = (user) => {
  console.log(user)
  return (dispatch) => {
    // let user = JSON.parse(localStorage.getItem('authUser'));
    var docRef = db
      .collection('product_categories')
      // .where('ref_category_id', '==', user?.uid)
      // .where('created_by', '==', user?.uid);
    const categories = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.auto_id = doc.id;
        data.auth_id = doc.id;
        data.key = doc.id;
        categories.push(data);

        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_CATEGORIES, payload: categories});
    });
  };
};

// export function onAddNewCategory(event) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       // let user = JSON.parse(localStorage.getItem('authUser'));
//       var docRef = firebase.firestore().collection('product_categories').doc();
//       docRef
//         .set({
//           auto_id: docRef.id,
//           category_id: docRef.id,
//           category_name: event.category_name,
//           created_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           created_by: firebase.auth().currentUser.uid,
//           updated_by: firebase.auth().currentUser.uid,
//         })
//         .then(() => {
//           console.log('Document successfully written!');
//           resolve('added');
//           dispatch({type: FETCH_SUCCESS});
//         })
//         .catch((error) => {
//           console.error('Error writing document: ', error);
//           reject(error);
//         });
//     });
//   };
// }

export function onUpdateCategory(event) {
  console.log(event.id)
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('product_categories')
        .doc(event.id);
      docRef
        .update({
          category_name: event.category_name,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}
export function onDeleteCategory(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(event);
      var docRef = firebase
        .firestore()
        .collection('product_categories')
        .doc(event);
      docRef
        .delete()
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}
