import {
  ADD_CART_ITEM,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CUSTOMERS,
  GET_ECOMMERCE_LIST,
  GET_RECENT_ORDER,
  REMOVE_CART_ITEM,
  SET_FILTER_DATA,
  SET_PRODUCT_DATA,
  SET_PRODUCT_VIEW_TYPE,
  UPDATE_CART_ITEM,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {db} from '../../@crema/services/auth/firebase/firebase';
// import { initial } from 'lodash';
import firebase from 'firebase/app';

export const onGetEcommerceData = (filterData, user) => {
  console.log(filterData, user);
  // return (dispatch) => {
  //   dispatch({type: FETCH_START});
  //   Api.get('/api/ecommerce/list', {
  //     params: {filterData},
  //   })
  //     .then((data) => {
  //       if (data.status === 200) {
  //         dispatch({type: FETCH_SUCCESS});
  //         dispatch({type: GET_ECOMMERCE_LIST, payload: data.data});
  //       } else {
  //         dispatch({
  //           type: FETCH_ERROR,
  //           payload: 'Something went wrong, Please try again!',
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch({type: FETCH_ERROR, payload: error.message});
  //     });
  // };
  return (dispatch) => {
    var docRef = db.collection('products').where('created_by', '==', user?.uid);
    const adminslist = [];
    var intial= 0;
    // var arr_img = [];
    docRef.get().then((querySnapshot) => {
      if (querySnapshot.empty) {
        dispatch({type: GET_ECOMMERCE_LIST, payload: adminslist});
      } else { 
        querySnapshot.forEach((doc) => {
          var data = doc.data();
          data.key = doc.id;
          var arr_img = [];
          data.pd_image.map((img)=>{
            intial++
            var obj_arr =  {
                uid: intial,
                edit: true,
                url: img.img_url,
              }
              arr_img.push(obj_arr)
          })
          data.mod_imgs = arr_img
          console.log(data)
          adminslist.push(data);
          console.log(adminslist.length, querySnapshot.size)
          if (adminslist.length === querySnapshot.size) {
            dispatch({type: GET_ECOMMERCE_LIST, payload: adminslist});
          }
        });
      }
     
    });
  };
};
export const onGetEcommerceData1 = (user) => {
  console.log(user);
  return new Promise((resolve, reject) => {
    var docRef = db.collection('products').where('created_by', '==', user?.uid);
    const adminslist = [];
    var intial= 0;
    // var arr_img = [];
    docRef.get().then((querySnapshot) => {
      if (querySnapshot.empty) {
        reject(adminslist);
      } else { 
        querySnapshot.forEach((doc) => {
          var data = doc.data();
          data.key = doc.id;
          var arr_img = [];
          data.pd_image.map((img)=>{
            intial++
            var obj_arr =  {
                uid: intial,
                edit: true,
                url: img.img_url,
              }
              arr_img.push(obj_arr)
          })
          data.mod_imgs = arr_img
          console.log(data)
          adminslist.push(data);
          console.log(adminslist.length, querySnapshot.size)
          if (adminslist.length === querySnapshot.size) {
            resolve(adminslist);
          }
        });
      }
     
    });
  });
};
export const  getselectedCategory = (catbucket) => {
  console.log("category-------------------------",catbucket)
  return new Promise((resolve) => {
  // return (dispatch) => {
    var catlist = catbucket;
    console.log(catlist)
    let productdoc = [];
      catlist.forEach(id => {
          console.log(id.ref_category_id)
          var catref = firebase.firestore().collection("product_categories")
          .doc(id.ref_category_id)
          // .where("category_id", "==", cp.cat_id)
          
          catref.get().then((doc) => {
            // console.log(cquerySnapshot.size)
              // cquerySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data(),);
                var data = doc.data();
                data.key = doc.id;
                data.title = doc.data().category_name;
                // doc.data() is never undefined for query doc snapshots
                
                productdoc.push(data);
                console.log(productdoc)
                if (catlist.length === productdoc.length) {
                  resolve(productdoc);
                }
              // });
          })
         
          // dispatch({type: GET_TREE_CAT, payload: productdoc});
        })
  // }
})
};
export const  getselectedProduct = (cat_id) => {
  console.log(cat_id)
  
  return (dispatch) => {
    var docRef = firebase.firestore().collection("products")
    .where("ref_category_id", "==", cat_id)
    .where("created_by", "==", firebase.auth().currentUser.uid)
    
    const productdoc = [];
    var intial= 0;
    docRef.get().then((querySnapshot) => {
      // if (productdoc.length === 0) {
         dispatch({type: GET_ECOMMERCE_LIST, payload: productdoc});
      // } else {
        querySnapshot.forEach((doc) => {
          var data = doc.data();
          data.key = doc.id;
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data(), querySnapshot);
          var arr_img = [];
          data.pd_image.map((img)=>{
            intial++
            var obj_arr =  {
                uid: intial,
                edit: true,
                url: img.img_url,
              }
              arr_img.push(obj_arr)
          })
          data.mod_imgs = arr_img
          productdoc.push(data);
          console.log(productdoc)
          // resolve(meetDoc)
          dispatch({type: GET_ECOMMERCE_LIST, payload: productdoc});
        });
    })
  }
};
export const getProductDetail = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/ecommerce/get', {
      params: {id: id},
    })
      .then((data) => {
        // if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SET_PRODUCT_DATA, payload: data.data});
        //  else {
        //   dispatch({
        //     type: FETCH_ERROR,
        //     payload: 'Something went wrong, Please try again!',
        //   });
        // }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error});
      });
  };
};

export const getRecentOrders = (search, page) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/ecommerce/orders', {
      params: {search, page},
    })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_RECENT_ORDER, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getCustomers = (search, page) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/ecommerce/customers', {
      params: {search, page},
    })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CUSTOMERS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getCartItems = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //   Api.get('/api/cart/get')
    //     .then((data) => {
    //       if (data.status === 200) {
    dispatch({type: FETCH_SUCCESS});
    //         dispatch({type: SET_CART_ITEMS, payload: data.data});
    //       } else {
    //         dispatch({
    //           type: FETCH_ERROR,
    //           payload: 'Something went wrong, Please try again!',
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       dispatch({type: FETCH_ERROR, payload: error.message});
    //     });
  };
};

export const setViewType = (viewType) => {
  return (dispatch) => {
    dispatch({type: SET_PRODUCT_VIEW_TYPE, payload: viewType});
  };
};

export const removeCartItem = (data) => {
  return (dispatch) => {
    dispatch({type: REMOVE_CART_ITEM, payload: data});
  };
};

export const updateCartItem = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_CART_ITEM, payload: data});
  };
};

export const addItemToCart = (data) => {
  return (dispatch) => {
    dispatch({type: ADD_CART_ITEM, payload: data});
  };
};

export const setCurrentProduct = (product) => {
  return (dispatch) => {
    dispatch({type: SET_PRODUCT_DATA, payload: product});
  };
};

export const setFilters = (filters) => {
  return (dispatch) => {
    dispatch({type: SET_FILTER_DATA, payload: filters});
  };
};
