import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const Page1 = React.lazy(() => import('./Page1'));
const Page2 = React.lazy(() => import('./Page2'));
const Plan = React.lazy(() => import('./Plans'));
const AddPlan = React.lazy(() => import('./Plans/AddPlan'));
const AddUser = React.lazy(() => import('./Page1/AddUser'));
const Addbrand = React.lazy(() => import('./Page2/AddBrands'));
const TrainingModule = React.lazy(() => import('./Training Modules'));
const AddTrainingModule = React.lazy(() =>
  import('./Training Modules/Add Training Module'),
);
const Blog = React.lazy(() => import('./Blog'));
const AddBlog = React.lazy(() => import('./Blog/AddBlog'));
const Products = React.lazy(() => import('./product'));
const ProductDetail = React.lazy(() => import('./ProductDetail'));
// const Category = React.lazy(() => import('./Category'));
// const AddCategory = React.lazy(() => import('./Category/AddCategory'));
// const SubCategory = React.lazy(() => import('./Sub Category'));
const ProductFeedback = React.lazy(() => import('./ProductFeedback'));
// const AddSubCategory = React.lazy(() =>
//   import('./Sub Category/Add SubCategory'),
// );
const AddProduct = React.lazy(() => import('./product/AddProduct'));
const Partners = React.lazy(() => import('./Partners'));
const AddPartner = React.lazy(() => import('./Partners/AddPartners'));
const Meeting = React.lazy(() => import('./Meetings'));
const MeetingView = React.lazy(() => import('./Meetings/MeetingView'));
const AddMeeting = React.lazy(() => import('./Meetings/AddMeeting'));
const Calendar = React.lazy(() => import('./Calendar'));
const Mapping = React.lazy(() => import('./Product Mapping/Mapping'));
const ViewFeedback = React.lazy(() => import('./ProductFeedback/ViewFeedback'));
const DashBoard = React.lazy(() => import('./DashBoard'));
const Chat = React.lazy(() => import('./Chat'));

export const samplePagesConfigs = [
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/sample/page-1',
    element: <Page1 />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/sample/adduser',
    element: <AddUser />,
  },

  {
    permittedRole: RoutePermittedRole.admin,
    path: '/sample/addbrand',
    element: <Addbrand />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/sample/addplan',
    element: <AddPlan />,
  },
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/sample/page-2',
    element: <Page2 />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/plan',
    element: <Plan />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Blog',
    element: <Blog />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/AddBlog',
    element: <AddBlog />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/TrainingModule',
    element: <TrainingModule />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/AddTrainingModule',
    element: <AddTrainingModule />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/products',
    element: <Products />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: ['/sample/product_detail/', '/sample/product_detail/:id'],
    element: <ProductDetail />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/MeetingView',
    element: <MeetingView />,
  },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/Category',
  //   element: <Category />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/AddCategory',
  //   element: <AddCategory />,
  // },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/SubCategory',
  //   element: <SubCategory />,
  // },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/DashBoard',
    element: <DashBoard />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Chat',
    element: <Chat />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/ProductFeedback',
    element: <ProductFeedback />,
  },
  // {
  //   permittedRole: RoutePermittedRole.user,
  //   path: '/sample/AddSubCategory',
  //   element: <AddSubCategory />,
  // },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/addProduct',
    element: <AddProduct />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Partners',
    element: <Partners />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/addPartners',
    element: <AddPartner />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Meetings',
    element: <Meeting />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/AddMeeting',
    element: <AddMeeting />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Calendar',
    element: <Calendar />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Mapping',
    element: <Mapping />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/ViewFeedback',
    element: <ViewFeedback />,
  },
];
