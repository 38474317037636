import {GET_QUIZ, FETCH_SUCCESS} from '../../shared/constants/ActionTypes';
import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export const getQuiz = (user) => {
  // let user = JSON.parse(localStorage.getItem('authUser'));
  // console.log(user)
  return (dispatch) => {
    var docRef = db
      .collection('training_module')
      .where('created_by', '==', user?.uid);
      // .where('ref_manager_id', '==', user?.uid);
    const quizlist = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.auto_id = doc.id;
        data.key = doc.id;
        quizlist.push(data);
        console.log(quizlist)
        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_QUIZ, payload: quizlist});
    });
  };
};
// export const getAnsweredQuiz = (user) => {
//   // let user = JSON.parse(localStorage.getItem('authUser'));
//   // console.log(user)
//   return (dispatch) => {
//     var docRef = db
//       .collection('training_module')
//       .where('created_by', '==', user?.uid).where('started', '==', true);
//     const quizlist = [];
//     docRef.get().then((querySnapshot) => {
//       console.log(querySnapshot);
//       querySnapshot.forEach((doc) => {
//         // if (doc.data().ref_brand_code == false) {
//         console.log(doc.id, ' => ', doc.data(), querySnapshot);
//         var data = doc.data();
//         data.auto_id = doc.id;
//         data.key = doc.id;
//         quizlist.push(data);
//         console.log(quizlist)
//         // doc.data() is never undefined for query doc snapshots
//       });
//       dispatch({type: GET_QUIZ, payload: quizlist});
//     });
//   };
// };
export function addQuiz(event,questions, fileList) {
  console.log('video', event);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var metadata = {
        contentType: 'video/mp4',
      };
      // var videoarry = [];
      var docRef = firebase.firestore().collection('training_module').doc();
      var storageRef = firebase.storage().ref();
      console.log(fileList);
      if (event.video_file) {
        fileList.forEach((f) => {
          console.log(f)
          console.log('video process', storageRef);
          var uploadTask = storageRef
            .child('/trainingmodule/' + f.name)
            .put(f, metadata);
          console.log(uploadTask);
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              reject(error);
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;

                // ...

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                // var obj_img = {
                //   vdo_url: downloadURL,
                // };
                // videoarry.push(obj_img);
                // console.log(obj_img);
                // if (videoarry.length === fileList.length) {
                  // console.log(videoarry);
                  // event.video_file = videoarry;
                  docRef.set({
                    auto_id: docRef.id,
                    ref_manager_id: firebase.auth().currentUser.uid,
                    is_active: "1",
                    started: false,
                    questions: questions,
                    question_raw: event.question_raw,
                    training_module_name: event.training_module_name,
                    video_url: event.video_url ?? "0",
                    video_file: downloadURL,
                    quiz_title: event.quiz_title,
                    created_at: firebase.firestore.Timestamp.fromDate(new Date()),
                    updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
                    created_by: firebase.auth().currentUser.uid,
                    updated_by: firebase.auth().currentUser.uid
                  });
                  resolve('added');
                // }
              });
            },
          );
        });
      } else {
        console.log('module', event);
        let post = {
          auto_id: docRef.id,
          ref_manager_id: firebase.auth().currentUser.uid,
          is_active: "1",
          started: false,
          questions: questions,
          question_raw: event.question_raw,
          training_module_name: event.training_module_name,
          video_url: event.video_url ?? "0",
          video_file: event.downloadURL ?? "",
          quiz_title: event.quiz_title,
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid
        };
        docRef.set(post);
        resolve('added');
        dispatch({type: FETCH_SUCCESS});
      }
    });
  };
}

// export function addQuiz(event,questions) {
//   console.log(event)
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       // let user = JSON.parse(localStorage.getItem('role'));
//       var docRef = firebase.firestore().collection('training_module').doc();
//       docRef
//         .set({
//           auto_id: docRef.id,
//           ref_manager_id: firebase.auth().currentUser.uid,
//           is_active: "1",
//           questions: questions,
//           question_raw: event.question_raw,
//           training_module_name: event.training_module_name,
//           video_url: event.video_url,
//           quiz_title: event.quiz_title,
//           created_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           created_by: firebase.auth().currentUser.uid,
//           updated_by: firebase.auth().currentUser.uid
//         })
//         .then(() => {
//           console.log('Document successfully written!');
//           resolve('added');
//           dispatch({type: FETCH_SUCCESS});
//         })
//         .catch((error) => {
//           console.error('Error writing document: ', error);
//           reject(error);
//         });
//     });
//   };
// }

// export function updateQuiz(event,questionSet,questions,moduledata) {
//   // console.log(questionSet.length)
//   console.log(moduledata)
//   console.log(questions)
//   console.log(questions.legth)
//   console.log(event)
//   console.log(event.id)
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var docRef = firebase
//         .firestore()
//         .collection('training_module')
//         .doc(event.id);
//       const combine = moduledata.concat(questions);
//         event.question_raw = combine;
//         console.log(combine)
//       docRef
//         .update({
//           questions: questionSet,
//           question_raw: combine,
//           training_module_name: event.training_module_name,
//           video_url: event.video_url,
//           quiz_title: event.quiz_title,
//           is_active: event.is_active,
//           updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           updated_by: firebase.auth().currentUser.uid
//         })
//         .then(() => {
//           console.log('Document successfully written!');
//           resolve('added');
//           dispatch({type: FETCH_SUCCESS});
//         })
//         .catch((error) => {
//           console.error('Error writing document: ', error);
//           reject(error);
//         });
//     });
//   };
// }
export function getSelectedQuiz(docid) {
  return new Promise((resolve, reject) => {
      console.log("Invoked - Qizzz", docid)
      firebase.firestore()
      .collection("training_module")
      .doc(docid).get().then(function (doc) {
          var data = doc.data();
          data.id = doc.id;
          console.log(data)
          resolve(data);
      })
      .catch((err) => {
          console.log(err);
          reject(err.message)
      });
  })
};
export function updateQuizQuestion(event, edited ) {
  console.log(event)
  console.log(edited)
  console.log(event.id)
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('training_module')
        .doc(event.id);
      docRef
        .update({
          question_raw: edited,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid
        },
        )
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}
export function updateQuiz(event,questionSet,questions,moduledata, fileList) {
  console.log(event);
  console.log(fileList);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var metadata = {
        contentType: 'video/mp4',
      };
      // console.log(prodimage);
      console.log(event.id);
      var docRef = firebase.firestore().collection('training_module').doc(event.id);
      // const productRef = firebase.firestore().collection("products").doc(event.id)
      console.log(fileList.length);
      var storageRef = firebase.storage().ref();
      const combine = moduledata.concat(questions);
      event.question_raw = combine;
      console.log(combine)
      if (fileList.length === 0) {
        docRef
          .update({
            questions: questionSet,
            question_raw: combine,
            training_module_name: event.training_module_name,
            video_url: event.video_url ?? "0",
            quiz_title: event.quiz_title,
            is_active: event.is_active,
            updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
            updated_by: firebase.auth().currentUser.uid
          })
          .then(() => {
            console.log('Document successfully updated!');
            resolve('updated');
            dispatch({type: FETCH_SUCCESS});
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        if (event.video_file) {
        fileList.forEach((f) => {
          var uploadTask = storageRef
            .child('/trainingmodule/' + f.name)
            .put(f, metadata);
          // console.log(uploadTask)
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  // console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  // console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              reject(error);
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;

                // ...

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                // console.log(imagearray)
                  docRef.update({
                    video_file: downloadURL,
                    // questions: questionSet,
                    // question_raw: combine,
                    // training_module_name: event.training_module_name,
                    // quiz_title: event.quiz_title,
                    // is_active: event.is_active,
                    // updated_at: firebase.firestore.Timestamp.fromDate(
                    //   new Date(),
                    // ),
                    // updated_by: firebase.auth().currentUser.uid,
                  });
                  resolve('updated');
                  dispatch({type: FETCH_SUCCESS});
              });
            },
          );
        });
      }
      }
    });
  };
}
// export function updateQuiz(event,questionSet,questions,moduledata, fileList) {
//   console.log('video', event);
//   console.log(fileList)
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       var metadata = {
//         contentType: 'video/mp4',
//       };
//       // var videoarry = [];
//       var docRef = firebase.firestore().collection('training_module').doc();
//       var storageRef = firebase.storage().ref();
//       console.log(fileList);
//       if (event.video_file) {
//         fileList.forEach((f) => {
//           console.log(f)
//           console.log('video process', storageRef);
//           var uploadTask = storageRef
//             .child('/trainingmodule/' + f.name)
//             .put(f, metadata);
//           console.log(uploadTask);
//           uploadTask.on(
//             firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
//             (snapshot) => {
//               // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//               var progress =
//                 (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//               console.log('Upload is ' + progress + '% done');
//               switch (snapshot.state) {
//                 case firebase.storage.TaskState.PAUSED: // or 'paused'
//                   console.log('Upload is paused');
//                   break;
//                 case firebase.storage.TaskState.RUNNING: // or 'running'
//                   console.log('Upload is running');
//                   break;
//               }
//             },
//             (error) => {
//               reject(error);
//               // A full list of error codes is available at
//               // https://firebase.google.com/docs/storage/web/handle-errors
//               switch (error.code) {
//                 case 'storage/unauthorized':
//                   // User doesn't have permission to access the object
//                   break;
//                 case 'storage/canceled':
//                   // User canceled the upload
//                   break;

//                 // ...

//                 case 'storage/unknown':
//                   // Unknown error occurred, inspect error.serverResponse
//                   break;
//               }
//             },
//             () => {
//               // Upload completed successfully, now we can get the download URL
//               uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
//                 console.log('File available at', downloadURL);
//                 const combine = moduledata.concat(questions);
//                   event.question_raw = combine;
//                   console.log(combine)
//                   docRef.update({
//                     questions: questionSet,
//                     question_raw: combine,
//                     training_module_name: event.training_module_name,
//                     video_url: event.video_url ?? "0",
//                     video_file: downloadURL,
//                     quiz_title: event.quiz_title,
//                     is_active: event.is_active,
//                     updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
//                     updated_by: firebase.auth().currentUser.uid
//                   });
//                   resolve('added');
//                 // }
//               });
//             },
//           );
//         });
//       }
//        else {
//         console.log('module', event);
//         let post = {
//           questions: questionSet,
//           question_raw: questions,
//           training_module_name: event.training_module_name,
//           video_url: event.video_url ?? "0",
//           video_file: event.downloadURL ?? "",
//           quiz_title: event.quiz_title,
//           is_active: event.is_active,
//           updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
//           updated_by: firebase.auth().currentUser.uid
//         };
//         docRef.update(post);
//         resolve('added');
//         dispatch({type: FETCH_SUCCESS});
//       }
//     });
//   };
// }
export function deleteQuiz(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(event);

      var docRef = firebase
        .firestore()
        .collection('training_module')
        .doc(event);
      // Set the "capital" field of the city 'DC'
      docRef
        .update({
          is_active: "0",
        })
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}
