import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyBs9zagpk1MLm5U-nCWBFRoFYfc5W0Gmdw',
  authDomain: 'sprintb2b-f68a0.firebaseapp.com',
  projectId: 'sprintb2b-f68a0',
  storageBucket: 'sprintb2b-f68a0.appspot.com',
  messagingSenderId: '344257047529',
  appId: '1:344257047529:web:f1457ca824cc3ca5217978',
  measurementId: 'G-HKMEV9K2MJ',
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

var config1 = {
  apiKey: 'AIzaSyBs9zagpk1MLm5U-nCWBFRoFYfc5W0Gmdw',
  authDomain: 'sprintb2b-f68a0.firebaseapp.com',
  projectId: 'sprintb2b-f68a0',
  storageBucket: 'sprintb2b-f68a0.appspot.com',
  messagingSenderId: '344257047529',
  appId: '1:344257047529:web:f1457ca824cc3ca5217978',
  measurementId: 'G-HKMEV9K2MJ',
};

var secondaryApp = firebase.initializeApp(config1, 'Secondary');

const secauth = secondaryApp.auth();

export {
  auth,
  db,
  storage,
  secauth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
};
