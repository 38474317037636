import {
  GET_BRANDS,
  GET_BRANDS_ADMIN,
  GET_PLAN,
  GET_REGIONS,
  GET_TREE_CAT,
  GET_CATEGORIES,
  GET_SUBCATEGORIES,
  GET_PARTNERS,
  GET_EVENTS,
  SET_MEETING_DATA,
  GET_QUIZ,
  GET_BLOGS,
  GET_PRODUCT_FEEDDBACK,
  GET_PRODUCTS,
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  brands: [],
  adminuser: [],
  plan: [],
  blog: [],
  regions: [],
  treecat: [],
  meetings: [],
  partners: [],
  trmodule: [],
  prodfb: [],
  products: [],
  meetingview: null,
};

const DataReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case GET_BLOGS:
      return {
        ...state,
        blog: action.payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_QUIZ:
      return {
        ...state,
        trmodule: action.payload,
      };
    case SET_MEETING_DATA:
      return {
        ...state,
        meetingview: action.payload,
      };
    case GET_BRANDS_ADMIN:
      return {
        ...state,
        adminuser: action.payload,
      };
    case GET_PLAN:
      return {
        ...state,
        plan: action.payload,
      };
    case GET_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case GET_TREE_CAT:
      return {
        ...state,
        treecat: action.payload,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_PRODUCT_FEEDDBACK:
      return {
        ...state,
        prodfb: action.payload,
      };
    case GET_SUBCATEGORIES:
      return {
        ...state,
        subcategory: action.payload,
      };
    case GET_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };
    case GET_EVENTS:
      return {
        ...state,
        meetings: action.payload,
      };

    default:
      return state;
  }
};

export default DataReducer;
