import {GET_PARTNERS, FETCH_SUCCESS} from '../../shared/constants/ActionTypes';
import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// const baseurl = 'https://us-central1-hub-system-37f3a.cloudfunctions.net/';

const baseurl = 'https://us-central1-sprintb2b-f68a0.cloudfunctions.net/';

export const GetPartners = (user) => {
  return (dispatch) => {
    var docRef = db
      .collection('channel_partner_basic_info')
      .where('created_by', 'array-contains-any', [user?.uid]);
    const partners = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot.size)
      querySnapshot.forEach((doc) => {
        var data = doc.data()
        data.auto_id = doc.id;
        data.key = doc.id;
        partners.push(data);
      });
      dispatch({type: GET_PARTNERS, payload: partners});
    });
  };
};

export function onAddNewPartner(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('channel_partner_basic_info')
        .doc();
      docRef
        .set({
          auto_id: docRef.id,
          channel_partner_code: '',
          channel_partner_email: event.channel_partner_email,
          channel_partner_firstname: event.channel_partner_firstname,
          channel_partner_lastname: event.channel_partner_lastname,
          channel_partner_mobilenumber: event.channel_partner_mobilenumber,
          channel_partner_last_login: '',
          user_device_token: '',
          user_device_type: '',
          is_active: '1',
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: [firebase.auth().currentUser.uid],
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export function createPartner(event) {
  return () => {
    console.log(event);
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          channel_partner_email: event.channel_partner_email.toLowerCase(),
          channel_partner_firstname: event.channel_partner_firstname,
          channel_partner_lastname: event.channel_partner_lastname,
          channel_partner_mobilenumber: event.channel_partner_mobilenumber,
          created_by: firebase.auth().currentUser.uid,
          is_active: '1',
        }),
      };
      fetch(baseurl + 'createchannelpartner', requestOptions)
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });
  };
}
export function onUpdatePartner(event) {
  console.log(event);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('channel_partner_basic_info')
        .doc(event.id);
      docRef
        .update({
          channel_partner_email: event.channel_partner_email.toLowerCase(),
          channel_partner_firstname: event.channel_partner_firstname ?? '',
          channel_partner_lastname: event.channel_partner_lastname ?? '',
          channel_partner_mobilenumber:
            event.channel_partner_mobilenumber ?? '',
          // is_active: event.is_active ?? '',
          is_active: String(event.is_active) === 'true' ? true : false,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export function onDeletePartner(event) {
  // console.log(event)
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(event);

      var docRef = firebase
        .firestore()
        .collection('channel_partner_basic_info')
        .doc(event);
      docRef
        .update({
          is_active: false,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}