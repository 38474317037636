import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const UserProfile = React.lazy(() => import('./UserProfile'));
export const profileConfig = [
  {
    permittedRole: RoutePermittedRole.admin,
    path: '/my-profile',
    element: <UserProfile />,
  },
];
