import saMessages from '../locales/fr_FR.json';
import hiIN from 'antd/lib/locale/hi_IN';

const inLang = {
  messages: {
    ...saMessages,
  },
  antLocale: hiIN,
  locale: 'hi_IN',
};
export default inLang;
