import {
  ADD_NEW_MESSAGE,
  DELETE_MESSAGE,
  DELETE_USER_MESSAGES,
  EDIT_MESSAGE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CONNECTIONS_LIST,
  GET_USER_MESSAGES,
  SELECT_USER,
  SHOW_MESSAGE,
  TOGGLE_CHAT_DRAWER,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/helper/Utils';
import {db} from '../../@crema/services/auth/firebase/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import firebase from 'firebase/app';

// export const getConnectionList = () => {
//   const {messages} = appIntl();
//   return (dispatch) => {
//     dispatch({type: FETCH_START});
//     Api.get('/api/chatApp/connections')
//       .then((data) => {
//         if (data.status === 200) {
//           dispatch({type: FETCH_SUCCESS});
//           dispatch({type: GET_CONNECTIONS_LIST, payload: data.data});
//         } else {
//           dispatch({
//             type: FETCH_ERROR,
//             payload: messages['message.somethingWentWrong'],
//           });
//         }
//       })
//       .catch((error) => {
//         dispatch({type: FETCH_ERROR, payload: error.message});
//       });
//   };
// };
export const getConnectionList = (user) => {
  console.log(user)
  return (dispatch) => {
    var docRef = db
      .collection('channel_partner_basic_info')
      .where('created_by', 'array-contains-any',  [user?.uid])

      // .where('created_by', 'array-contains-any', [user?.uid]);
    // .orderBy('created_at', 'desc');
    const partners = [];
    // var arraypartner = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot.size);
      partners.no_of_partner = querySnapshot.size;
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.auto_id = doc.id;
        data.status = doc.istatus?? "online";
        data.name = doc.data().channel_partner_firstname + " " + doc.data().channel_partner_lastname;
        data.username = doc.data().channel_partner_email;
        // data.image = doc.data().channel_partner_firstname;
        partners.push(data);

        console.log(partners)
        // doc.data() is never undefined for query doc snapshots
      });
     dispatch({type: GET_CONNECTIONS_LIST, payload: partners});
    });
  };
};
// export const getConnectionMessages = (id) => {
//   console.log(id)
//   const {messages} = appIntl();
//   return (dispatch) => {
//     dispatch({type: FETCH_START});
//     Api.get('/api/chatApp/connection/messages', {
//       params: {
//         id,
//       },
//     })
//       .then((data) => {
//         if (data.status === 200) {
//           dispatch({type: FETCH_SUCCESS});
//           dispatch({type: GET_USER_MESSAGES, payload: data.data});
//         } else {
//           dispatch({
//             type: FETCH_ERROR,
//             payload: messages['message.somethingWentWrong'],
//           });
//         }
//       })
//       .catch((error) => {
//         dispatch({type: FETCH_ERROR, payload: error.message});
//       });
//   };
// };
export const getConnectionMessages = (id) => {
  console.log(id)
  return (dispatch) => {
    var docRef = db
    .collection('message')
    // .where('ref_category_id', '==', user?.uid)
    .where('channelId', '==', id);
  const categories = [];
  docRef.get().then((querySnapshot) => {
    console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      // if (doc.data().ref_brand_code == false) {
      console.log(doc.id, ' => ', doc.data(), querySnapshot);
      var data = doc.data();
      // data.auto_id = doc.id;
      // data.auth_id = doc.id;
      // data.key = doc.id;
      categories.push(data);
      console.log(categories)
      // doc.data() is never undefined for query doc snapshots
      dispatch({type: GET_USER_MESSAGES, payload: categories});

    });
  });
  };
};

export const onSendMessage = (channelId, message) => {
  console.log(channelId, message)
  var messageData =[]
  messageData.push(message)
  return (dispatch) => {
      var docRef = firebase.firestore().collection('message').doc();
      console.log(channelId === docRef.channelId)
    // if (channelId === doc.data().channelId) {
    //   var newarry = []
    //   newarry.push(message)
    //   const combine = messageData.concat(newarry);
    //   console.log(combine)
    // }
      docRef
        .set({
          channelId: channelId,
          messageData: messageData,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          // resolve('added');
          dispatch({type: FETCH_SUCCESS});
          
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          // reject(error);
        });
  };
};
export const onSendMessage1 = (channelId, message) => {
  const {messages} = appIntl();
  return (dispatch, getState) => {
    Api.post('/api/chatApp/message', {channelId, message})
      .then((data) => {
        console.log('data', data.data);
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (
            data.data.userMessages &&
            data.data.userMessages.messageData.length === 1 &&
            getState().chatApp.userMessages &&
            getState().chatApp.userMessages.messageData
          ) {
            console.log(
              'getState().chatApp.userMessages.messageData',
              getState().chatApp.userMessages.messageData,
            );
            dispatch({ 
              type: ADD_NEW_MESSAGE,
              payload: {
                data: {
                  ...data.data,
                  userMessages: {
                    ...data.userMessages,
                    messageData:
                      getState().chatApp.userMessages.messageData.concat(
                        data.data.userMessages.messageData,
                      ),
                  },
                },
              },
            });
            console.log(getState().chatApp.userMessages);
          } else {
            dispatch({
              type: ADD_NEW_MESSAGE,
              payload: {data: data.data},
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onClearChatHistory = (channelId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.post('/api/chatApp/clearChat', {channelId})
      .then((data) => {
        console.log('data', data.data);
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_USER_MESSAGES, payload: null});

          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['chatApp.clearChatMessage'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onEditMessage = (channelId, message) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.put('/api/chatApp/message', {channelId, message})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: EDIT_MESSAGE,
            payload: {data: data.data},
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onDeleteMessage = (channelId, messageId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    Api.post('/api/chatApp/delete/message', {channelId, messageId})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DELETE_MESSAGE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onDeleteConversation = (channelId) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.post('/api/chatApp/delete/user/messages', {channelId})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DELETE_USER_MESSAGES, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onSelectUser = (user) => {
  console.log(user)
  return (dispatch) => {
    dispatch({type: SELECT_USER, payload: user});
  };
};

export const onToggleChatDrawer = () => {
  return (dispatch) => {
    dispatch({type: TOGGLE_CHAT_DRAWER});
  };
};
