import React from 'react';
import {
  BiUser,
  BiUserCircle,
  // BiCloud,
  // BiQuestionMark,
  // BiMessage,
  // // BiCategory,
  // BiBasket,
  // BiCalendarEvent,
} from 'react-icons/bi';

const routesConfig = [
  {
    id: 'app',
    title: 'Menu',
    messageId: 'sidebar.sample',
    type: 'group',
    children: [
      {
        id: 'page-1',
        title: 'Brand Regions',
        messageId: 'sidebar.sample.page1',
        type: 'item',
        icon: <BiUser />,
        path: '/sample/page-1',
      },
      {
        id: 'page-2',
        title: 'Page 2',
        messageId: 'sidebar.sample.page2',
        type: 'item',
        icon: <BiUserCircle />,
        path: '/sample/page-2',
      },

      // {
      //   id: 'Category',
      //   title: 'Category',
      //   messageId: 'sidebar.sample.Category',
      //   type: 'item',
      //   icon: <BiCategory />,
      //   path: '/sample/Category',
      // },
      // {
      //   id: 'SubCategory',
      //   title: 'SubCategory',
      //   messageId: 'sidebar.sample.SubCategory',
      //   type: 'item',
      //   icon: <BiCategory />,
      //   path: '/sample/SubCategory',
      // },
      // {
      //   id: 'DashBoard',
      //   title: 'DashBoard',
      //   messageId: 'sidebar.sample.DashBoard',
      //   type: 'item',
      //   icon: <BiUser />,
      //   path: '/sample/DashBoard',
      // },
      // {
      //   id: 'Chat',
      //   title: 'Chat',
      //   messageId: 'sidebar.sample.Chat',
      //   type: 'item',
      //   icon: <BiMessage />,
      //   path: '/sample/Chat',
      // },
      // {
      //   id: 'ProductFeedback',
      //   title: 'ProductFeedback',
      //   messageId: 'sidebar.sample.ProductFeedback',
      //   type: 'item',
      //   icon: <BiMessage />,
      //   path: '/sample/ProductFeedback',
      // },
      // {
      //   id: 'Products',
      //   title: 'Products',
      //   messageId: 'sidebar.sample.Products',
      //   type: 'item',
      //   icon: <BiQuestionMark />,
      //   path: '/sample/Products',
      // },
      // {
      //   id: 'Partners',
      //   title: 'Partners',
      //   messageId: 'sidebar.sample.Partners',
      //   type: 'item',
      //   icon: <BiUserCircle />,
      //   path: '/sample/Partners',
      // },
      // {
      //   id: 'Blog',
      //   title: 'Blog',
      //   messageId: 'sidebar.sample.Blog',
      //   type: 'item',
      //   icon: <BiCloud />,
      //   path: '/sample/Blog',
      // },
      // {
      //   id: 'TrainingModule',
      //   title: 'TrainingModule',
      //   messageId: 'sidebar.sample.TrainingModule',
      //   type: 'item',
      //   icon: <BiQuestionMark />,
      //   path: '/sample/TrainingModule',
      // },
      // {
      //   id: 'Mapping',
      //   title: 'Mapping',
      //   messageId: 'sidebar.sample.Products Mapping',
      //   type: 'item',
      //   icon: <BiBasket />,
      //   path: '/sample/Mapping',
      // },

      // {
      //   id: 'Meetings',
      //   title: 'Meeting',
      //   messageId: 'sidebar.sample.Meetings',
      //   type: 'item',
      //   icon: <BiBasket />,
      //   path: '/sample/Meetings',
      // },
      // {
      //   id: 'Calendar',
      //   title: 'Calendar',
      //   messageId: 'sidebar.sample.Calendar',
      //   type: 'item',
      //   icon: <BiCalendarEvent />,
      //   path: '/sample/Calendar',
      // },
    ],
  },
];
export default routesConfig;
