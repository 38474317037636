import React, {useEffect} from 'react';
import {Layout} from 'antd';
import './AppFooter.style.less';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';

const {Footer} = Layout;

const AppFooter = () => {
  const {footer} = useLayoutContext();

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
      },
      'google_translate_element',
    );
  };
  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  if (footer) {
    return (
      <Footer className='app-main-footer'>
        <p>Copyright @Hub System 2023</p>
        <div className='footer-btn-view'>
          {/* <Button type='link' className='footer-btn' color='primary'>
            Buy Now
          </Button> */}
          <div id='google_translate_element'></div>
        </div>
      </Footer>
    );
  }
  return null;
};

export default AppFooter;
