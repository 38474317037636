import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  db,
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from './firebase';

import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
// import {useNavigate} from 'react-router-dom';
import {message} from 'antd';

const FirebaseContext = createContext();
const FirebaseActionsContext = createContext();

export const useFirebase = () => useContext(FirebaseContext);

export const useFirebaseActions = () => useContext(FirebaseActionsContext);

const FirebaseAuthProvider = ({children}) => {
  // const navigate = useNavigate();

  const [firebaseData, setFirebaseData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = auth.onAuthStateChanged(
      (user) => {
        setFirebaseData({
          user: user,
          isLoading: false,
          isAuthenticated: Boolean(user),
        });
      },
      () => {
        setFirebaseData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
        });
      },
      (completed) => {
        setFirebaseData({
          user: null,
          isLoading: false,
          isAuthenticated: completed,
        });
      },
    );

    return () => {
      getAuthUser();
    };
  }, [auth]);

  const getProvider = (providerName) => {
    switch (providerName) {
      case 'google': {
        return googleAuthProvider;
      }
      case 'facebook': {
        return facebookAuthProvider;
      }
      case 'twitter': {
        return twitterAuthProvider;
      }
      case 'github': {
        return githubAuthProvider;
      }
      default:
        return googleAuthProvider;
    }
  };

  const signInWithPopup = async (providerName) => {
    dispatch({type: FETCH_START});
    try {
      const {user} = await auth.signInWithPopup(getProvider(providerName));
      setFirebaseData({
        user,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const signInWithEmailAndPassword = async ({email, password}) => {
    dispatch({type: FETCH_START});
    var mailaddress = email.toLowerCase();
    console.log(mailaddress);
    try {
      db.collection('brand_admin')
        .where('brand_admin_email', '==', mailaddress)
        .where('ref_status_id', '==', 1)
        .where('ref_role_id', '==', 2)
        .get()
        .then((res) => {
          console.log(res);
          if (!res.empty) {
            auth.signInWithEmailAndPassword(mailaddress, password).then(
              (user) => {
                console.log(user);
                localStorage.setItem('role', 'admin');
                res.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, ' => ', doc.data());
                  auth.currentUser.updateProfile({
                    displayName:
                      doc.data().brand_admin_firstname +
                      ' ' +
                      doc.data().brand_admin_lastname,
                  });
                  db.collection('brands')
                    .where('brand_code', '==', doc.data().ref_brand_code)
                    .get()
                    .then((querySnapshot) => {
                      querySnapshot.forEach((doc1) => {
                        // doc.data() is never undefined for query doc snapshots
                        console.log(doc1.id, ' => ', doc1.data());
                        localStorage.setItem(
                          'Brand_Logo',
                          doc1.data().brand_logo,
                        );
                      });
                    })
                    .catch((error) => {
                      console.log('Error getting documents: ', error);
                    });
                });
                setFirebaseData({
                  user,
                  isAuthenticated: true,
                  isLoading: false,
                });
                dispatch({type: FETCH_SUCCESS});
              },
              (error) => {
                console.log(error);
                setFirebaseData({
                  isLoading: false,
                });
                dispatch({
                  type: FETCH_ERROR,
                  payload: error.message,
                });
              },
            );
          } else {
            db.collection('brand_admin')
              .where('brand_admin_email', '==', mailaddress)
              .where('ref_status_id', '==', 1)
              .where('ref_role_id', '==', 2)
              .get()
              .then((res) => {
                console.log(res);
                if (!res.empty) {
                  auth.signInWithEmailAndPassword(mailaddress, password).then(
                    (user) => {
                      console.log(user);
                      localStorage.setItem('role', 'admin');
                      res.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        console.log(doc.id, ' => ', doc.data());
                        auth.currentUser.updateProfile({
                          displayName:
                            doc.data().brand_admin_firstname +
                            ' ' +
                            doc.data().brand_admin_lastname,
                        });
                        db.collection('brands')
                          .where('brand_code', '==', doc.data().ref_brand_code)
                          .get()
                          .then((querySnapshot) => {
                            querySnapshot.forEach((doc1) => {
                              // doc.data() is never undefined for query doc snapshots
                              console.log(doc1.id, ' => ', doc1.data());
                              localStorage.setItem(
                                'Brand_Logo',
                                doc1.data().brand_logo,
                              );
                            });
                          })
                          .catch((error) => {
                            console.log('Error getting documents: ', error);
                          });
                      });
                      setFirebaseData({
                        user,
                        isAuthenticated: true,
                        isLoading: false,
                      });
                      dispatch({type: FETCH_SUCCESS});
                    },
                    // (error) => {
                    //   console.log(error);
                    //   setFirebaseData({
                    //     isLoading: false,
                    //   });
                    //   dispatch({
                    //     type: FETCH_ERROR,
                    //     payload: error.message,
                    //   });
                    // },
                  );
                } else {
                  //reject(this._handleError('User not found!'));
                  setFirebaseData({
                    ...firebaseData,
                    isAuthenticated: false,
                    isLoading: false,
                  });
                  dispatch({type: FETCH_ERROR, payload: 'User not found!'});
                }
              })
              .catch((error) => {
                console.log(error);
                setFirebaseData({
                  isLoading: false,
                });

                //reject(this._handleError(error));
              });
          }
        })
        .catch((error) => {
          console.log(error);
          setFirebaseData({
            isLoading: false,
          });
        });
      //const {user} = await auth.signInWithEmailAndPassword(email, password);
      //setFirebaseData({user, isAuthenticated: true, isLoading: false});
      //dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const createUserWithEmailAndPassword = async ({name, email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {user} = await auth.createUserWithEmailAndPassword(email, password);
      await auth.currentUser.sendEmailVerification({
        url: window.location.href,
        handleCodeInApp: true,
      });
      await auth.currentUser.updateProfile({
        displayName: name,
      });
      setFirebaseData({
        user: {...user, displayName: name},
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const updatePassword = async ({password}) => {
    console.log(`password`, password);
    dispatch({type: FETCH_START});
    try {
      const user = auth.currentUser;
      const isSuccess = await Promise.all([
        await auth.currentUser
          .updatePassword(password)
          .then(() => {
            console.log(`Password updated`);
            // Send new password
            setFirebaseData({
              user: {...user, password: password},
              isAuthenticated: true,
              isLoading: false,
            });
            dispatch({type: FETCH_SUCCESS});
            console.log(`Password updated in authentication`);
            message.success('Password updated Successfully');
            // setTimeout(() => {
            //   navigate('/sample/page-1');
            // }, 2000);
            // update your database changes
          })
          .catch((error) => {
            console.log(`Updating password in authentication failed: ${error}`);
            setFirebaseData({
              ...firebaseData,
              isAuthenticated: false,
              isLoading: false,
            });
            dispatch({type: FETCH_ERROR, payload: 'Updating password failed'});
          }),
      ]);
      return isSuccess;
    } catch (e) {
      console.log(`Updating password failed: ${e}`);
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: 'Updating password failed'});
    }
  };

  const logout = async () => {
    setFirebaseData({...firebaseData, isLoading: true});
    try {
      await auth.signOut();
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    } catch (error) {
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        ...firebaseData,
      }}>
      <FirebaseActionsContext.Provider
        value={{
          signInWithEmailAndPassword,
          createUserWithEmailAndPassword,
          signInWithPopup,
          logout,
          updatePassword,
        }}>
        {children}
      </FirebaseActionsContext.Provider>
    </FirebaseContext.Provider>
  );
};
export default FirebaseAuthProvider;

FirebaseAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
